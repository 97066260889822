import React, { useEffect, useMemo, useState } from "react";
// components
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header/Overview";
import { ApplicationState } from "../../../store";
// services
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ConfirmationDataPatient from "./confirmation";
import WaitingRoom from "./waiting";
import ConsentForm from "./consentForm";
import { initial } from "cypress/types/lodash";

export default function TelemedicineGuest() {
  const params = useParams();
  const initialStateData = {
    patient: {
      name: "",
      fiscal_number: "",
      document: "",
    },
    doctor: {
      name: "",
      document: "",
    },
    roomId: params.id,
  };

  // const telemedicineUrl = "jitsidev.rtsouza.work";
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const [data, setData] = useState(initialStateData);
  // const [fiscalNumber, setFiscalNumber] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [documentIsValid, setDocumentIsValid] = useState(false);
  const [acceptConsentForm, setAcceptConsentForm] = useState(false);
  const [inCall, setInCall] = useState(false);
  // const [refetch, setRefetch] = useState(true);

  const guestData = useSelector(
    (state: ApplicationState) => state.telemedicineAttendances.guest
  );

  useEffect(() => {
    if (guestData?.room?.doctor_in) {
      setInCall(true)
    }
  }, [guestData]);

  // useEffect(() => {
  //   setFiscalNumber(data.patient.fiscal_number);
  // }, [refetch]);

  const content = {
    tittle: "HeaderOverview",
    rows: [],
  };

  const handleConfirmDocument = () => {
    toast.success(
      "Confirmação realizada com sucesso!."
    );
    setTimeout(() => {
      setDocumentIsValid(true);
      setConfirm(true)
    }, 6000);
  };

  const handleAcceptConsentForm = () => {
    toast.success("Certo! estamos te redirecionando para a sala de espera!");
    setTimeout(() => {
      setAcceptConsentForm(true);
    }, 6000);
  };
  const handleRefuseConsentForm = () => {
    toast.warn("Tudo bem! volte quando quiser!");
    setTimeout(() => {
      navigate(0);
    }, 3000);
  };

  const handleExitWatingRoom = () => {
    toast.warning("Você saiu da sala de espera");
    setTimeout(() => {
      navigate(0);
    }, 3000);
  };

  const handleExitRoom = () => {
    toast.success("Sua chamada terminou");
    setTimeout(() => {
      navigate(0);
    }, 3000);
  };

  const handleDoctorInRoom = () => {
    setTimeout(() => {
      setInCall(true);
    }, 2000);
  };
  const handleDoctorOutRoom = () => {
    setTimeout(() => {
      setInCall(false);
    }, 2000);
  };

  return (
    <Container sx={{ padding: "15px" }}>
      <>
        <Grid container sx={{ background: "rgb(245, 245, 245)" }}>
          <Header content={content} guestData={guestData} guestView={confirm} attendanceType="telemedicine-guest" />
          <Grid
            sx={{
              paddingY: 1,
              width: "calc(100%)",
              margin: "0 auto",
            }}
          >
            <Box
              sx={{
                p: 1,
                borderRadius: 2,
                boxShadow: 3,
                mx: "auto",
                textAlign: "center",
                bgcolor: "background.paper",
              }}
            >
              {documentIsValid ? (
                acceptConsentForm ? (
                  <>
                    {inCall ? (
                      <JitsiMeeting
                        domain={guestData.telemedicine_url}
                        roomName={params.id}
                        onReadyToClose={() => handleExitRoom()}
                        configOverwrite={{
                          startWithAudioMuted: true,
                          disableModeratorIndicator: true,
                          startScreenSharing: true,
                          enableEmailInStats: false,
                          disableInviteFunctions: true,
                          toolbarButtons:['microphone', 'camera', 'desktop', 'chat', 'settings', 'raisehand', 'tileview', 'videoquality', 'filmstrip', 'hangup']
                        }}
                        userInfo={
                          {
                            displayName: initialStateData.patient.name,
                            email: null,
                          }
                        }
                        getIFrameRef={(iframeRef) => {
                          iframeRef.style.height = "600px";
                          // eslint-disable-next-line no-param-reassign
                        }}
                      />
                    ) : (
                      <WaitingRoom
                        data={{roomId: params.id, attendance: guestData}}
                        onExit={handleExitWatingRoom}
                        onDoctorInRoom={handleDoctorInRoom}
                        onDoctorOutRoom={handleDoctorOutRoom}
                      />
                    )}
                  </>

                ) : (
                  <ConsentForm
                    data={guestData}
                    onConfirm={handleAcceptConsentForm}
                    onRefuse={handleRefuseConsentForm}
                  />
                )
              ) : (
                <ConfirmationDataPatient
                  // cpf={fiscalNumber}
                  room_id={params.id}
                  onConfirm={handleConfirmDocument}
                />
              )}

            </Box>
          </Grid>
        </Grid>
      </>
    </Container>
  );
}
